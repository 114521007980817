import React, { useMemo } from 'react'
import { Dropdown } from '@carbon/react'
import { useTranslation } from 'react-i18next'
import { TopSummariesChart } from 'src/next/components/dashboard/tabs/charts/TopSummariesChart'
import {
  DropdownHeading,
  DropdownInlineLabel,
  DropdownSubheading,
} from 'src/next/components/Dropdown/DropdownHeading'
import useLocalStorage from 'src/next/hooks/useLocalStorage'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import ChartContainer from './ChartContainer'
import { TopSummariesChartSettingType } from './types'
import { TimePeriod } from 'src/next/utils/time'
import { useKubernetesWorkloadTopSummariesMetricDropdownItems } from './KubernetesWorkloadCharts.translations'

const useKubernetesWorkloadTopSummariesTypeDropdownItems = (): (Record<
  'id',
  TopSummariesChartSettingType
> & { label: string })[] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        id: 'WORKLOAD',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Workloads'),
      },
      {
        id: 'NAMESPACE',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Namespaces'),
      },
      {
        id: 'ORG',
        label: t('Workloads.TopCharts.Rollup.FilterItems.Organizations'),
      },
    ],
    [t],
  )
}

export const KubernetesWorkloadStackedBarChart = () => {
  const { t } = useTranslation()

  const summaryLimit = '5'

  const typeDropdownItems = useKubernetesWorkloadTopSummariesTypeDropdownItems()
  const metricDropdownItems =
    useKubernetesWorkloadTopSummariesMetricDropdownItems()

  const timeFilterItems = useMemo(
    () => [
      {
        id: 'daily',
        label: t('Workloads.TopCharts.Filters.DailyRollup'),
      },
      {
        id: 'weekly',
        label: t('Workloads.TopCharts.Filters.WeeklyRollup'),
      },
      {
        id: 'monthly',
        label: t('Workloads.TopCharts.Filters.MonthlyRollup'),
      },
    ],
    [t],
  )
  const [timeFilterRollup, setTimeFilterRollup] = useLocalStorage<TimePeriod>(
    'workloads-rollup-chart-time-filter',
    'daily',
  )

  const {
    dropdownProps: { selectedItem: type, ...typeDropdownProps },
  } = usePersistentDropdown('workloads-rollup-chart-type', typeDropdownItems)

  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(
    'workloads-rollup-chart-metric',
    metricDropdownItems,
  )

  // Not using string operation intentionally to make it easier to look up
  // translation IDs on the code search.
  const timePeriodTranslationMap = {
    daily: t('Common.TimePeriod.Daily'),
    weekly: t('Common.TimePeriod.Weekly'),
    monthly: t('Common.TimePeriod.Monthly'),
  }

  return (
    <ChartContainer
      selectedItem={timeFilterRollup}
      onChange={setTimeFilterRollup}
      menuItems={timeFilterItems}
      heading={
        <>
          <DropdownHeading>
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.FilterItems.Top')} {summaryLimit}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Type')}
              hideLabel
              selectedItem={type}
              {...typeDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
            {/* by <metric> */}
            <DropdownInlineLabel>
              {t('Workloads.TopCharts.Rollup.Heading.By')}{' '}
            </DropdownInlineLabel>
            <Dropdown
              type="inline"
              label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
              hideLabel
              selectedItem={metric}
              {...metricDropdownProps}
              itemToString={item => (item ? item.label.toLowerCase() : '')}
              itemToElement={item => (item ? <>{item.label}</> : null)}
            />
          </DropdownHeading>
          {/* ... Daily/Weekly/Monthly rollup */}
          <DropdownSubheading>
            {t('Common.Rollup', '{{timePeriod}} rollup', {
              timePeriod: timePeriodTranslationMap[timeFilterRollup],
            })}
          </DropdownSubheading>
        </>
      }
      data-testid="workloads-top-summaries-chart"
    >
      <TopSummariesChart
        summaryType={type.id}
        timePeriod={timeFilterRollup}
        summaryMetric={metric.id}
        summaryLimit={summaryLimit}
        width={640}
        height={320}
      />
    </ChartContainer>
  )
}
