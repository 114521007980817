import React, { useCallback, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import styled from 'styled-components'
import { PaddingProps, VictoryBarProps } from 'victory'

import { ComputeInstanceSummary, Summary } from '@cloudnatix-types/dashboard'

import { Box, Legend, LegendItem } from 'src/next/components'
import { BarGraphProps } from 'src/next/components/Graphs'
import { GraphTooltipContainerProps } from 'src/next/components/Graphs/GraphTooltipContainer/GraphTooltipContainer'
import Loading from 'src/next/components/Loading'
import StackedBarChart from 'src/next/components/StackedBarChart'
import { kubernetesWorkloadSummaryMetricMap } from 'src/next/constants/summary'
import {
  createFormatFn,
  getFormatType,
  getStackedMaxYDomain,
} from 'src/next/utils/graph.utils'
import { middleEllipsis } from 'src/next/utils/middle-ellipsis'
import { getTimePeriod } from 'src/next/utils/time'
import {
  TopSummariesChartSettingLimit,
  TopSummariesChartSettingMetric,
  TopSummariesChartSettingType,
} from 'src/next/containers/WorkloadCharts/types'
import { TimeRangeFromPeriod, TimePeriod } from 'src/next/utils/time'
import { useKubernetesTopSummaries } from 'src/next/containers/WorkloadCharts/useKubernetesTopSummaries'

import {
  ColorMap,
  getColorMapTopSummaries,
  getTransformTopSummariesData,
  TransformedData,
} from './TopSummariesUtils'

const LegendWrapper = styled.div`
  margin-left: var(--cds-spacing-04);
  width: 100%;
`

const createGraphConfig = (
  data: TransformedData,
  yAccessorKey: string,
  colorMap: ColorMap,
  selectedId?: string | null,
): BarGraphProps[] => {
  const formatTooltipValue = createFormatFn(getFormatType(yAccessorKey))

  return data.map((itemData, index) => ({
    id: `stacked-bar-${index}`,
    // For `StackedBarChart`, the number of elements in `activePoints` that `GraphTooltip` receives is the same as the number of `VictoryBar`s created.
    // For timestamps that doesn't have the same number of data as the number of `VictoryBar`s, there will be some meaningless elements in `activePoints`.
    // Not to show an empty tooltip rows for them, return `undefined` so that `GraphTooltip` ignores them.
    tooltipLabel: (pointData: any) =>
      pointData.groupingName
        ? middleEllipsis(pointData.groupingName, 15, 25)
        : undefined,
    type: 'bar',
    data: itemData.map((item: any) => {
      const { groupingName } = item
      return {
        ...item,
        style: {
          data: {
            fill: colorMap.find(u => u.id === groupingName)?.color,
            opacity: !selectedId || selectedId === groupingName ? 1 : 0.3,
          },
        },
      }
    }),
    tooltipValueTransformFn: (_value: any, pointData: any) =>
      formatTooltipValue(pointData[yAccessorKey]) || '',
  }))
}

interface TopSummariesChartProps {
  summaryType: TopSummariesChartSettingType
  timePeriod: TimePeriod
  summaryMetric: TopSummariesChartSettingMetric
  summaryLimit: TopSummariesChartSettingLimit
  selectedId?: string
  colorMap?: ColorMap
  containerProps?: GraphTooltipContainerProps
  barProps?: VictoryBarProps
  width: number
  height: number
  padding?: PaddingProps
}

export const TopSummariesChart = ({
  summaryType,
  timePeriod,
  summaryMetric,
  summaryLimit,
  selectedId,
  colorMap,
  containerProps,
  barProps,
  width,
  height,
  padding,
}: TopSummariesChartProps) => {
  const yAccessorKey = kubernetesWorkloadSummaryMetricMap[summaryMetric]
  const { startTimeNs, endTimeNs } = getTimePeriod(timePeriod)

  const { data, isLoading } = useKubernetesTopSummaries<TransformedData>(
    {
      summaryType: summaryType as any,
      summaryInterval: timePeriod.toUpperCase() as any,
      summaryMetrics: summaryMetric as any,
      limit: Number(summaryLimit),
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      select: data => {
        const transformedData = data.topSummaries
          ? Object.values(data.topSummaries)
              .filter(item => !!item.summaries)
              .map(({ summaries }) => summaries!)
          : []

        return getTransformTopSummariesData(transformedData)
      },
      useErrorBoundary: true,
    },
  )

  if (isLoading && !data) {
    return (
      <Box height="300px" position="relative">
        <Loading size="small" centered withOverlay={false} />
      </Box>
    )
  }

  if (!data?.length) return null

  return (
    <StackedSummariesChart
      data={data}
      timePeriod={timePeriod}
      selectedId={selectedId}
      yAccessorKey={yAccessorKey}
      colorMap={colorMap || getColorMapTopSummaries(data)}
      containerProps={containerProps}
      barProps={barProps}
      width={width}
      height={height}
      padding={padding}
    />
  )
}

interface StackedSummariesChartProps {
  data: TransformedData
  timePeriod: TimePeriod
  selectedId?: string | null
  multiLegendSelect?: boolean
  yAccessorKey: keyof Summary | keyof ComputeInstanceSummary | string
  colorMap: ColorMap
  containerProps?: GraphTooltipContainerProps | undefined
  barProps: VictoryBarProps | undefined
  width: number
  height: number
  padding: PaddingProps | undefined
}

export const StackedSummariesChart = ({
  data,
  timePeriod,
  selectedId,
  multiLegendSelect = true,
  yAccessorKey,
  colorMap,
  containerProps,
  barProps,
  width,
  height,
  padding,
}: StackedSummariesChartProps) => {
  const yFormatFn = useCallback(
    value =>
      createFormatFn(getFormatType(yAccessorKey), {
        cpu: { shortDisplayValue: true },
        memory: { maximumFractionDigits: 0 },
        disk: { maximumFractionDigits: 0 },
        currency: { minimumFractionDigits: 0 },
      })(value),
    [yAccessorKey],
  )

  const [checkedLegendItems, setCheckedLegendItems] = useState(
    colorMap.map(f => `${f.id}`),
  )

  useEffect(() => {
    setCheckedLegendItems(colorMap.map(f => `${f.id}`))
  }, [colorMap])

  const graphConfig = useMemo(() => {
    if (data.length === 0) throw new Error('graphConfig could not be generated')

    return createGraphConfig(data, yAccessorKey, colorMap, selectedId)
  }, [data, yAccessorKey, selectedId, colorMap])

  const handleLegendChange = (label: string) => {
    if (multiLegendSelect) {
      const legendItems = checkedLegendItems?.filter(c => c !== label)
      // make sure at least 1 item is selected
      if (!legendItems?.length) return false
      const newLegendItems = checkedLegendItems?.includes(label)
        ? legendItems
        : [...checkedLegendItems, label]
      setCheckedLegendItems(newLegendItems)
    } else {
      const allLegendItems = colorMap!.map(f => `${f.id}`)
      if (checkedLegendItems.length === 1 && label === checkedLegendItems[0]) {
        setCheckedLegendItems(allLegendItems)
      } else {
        setCheckedLegendItems([label])
      }
    }
  }

  const filteredGraphConfig = useMemo(() => {
    return graphConfig.map(config => {
      const filteredData = config.data.filter(d => {
        return d && checkedLegendItems?.includes(`${d.groupingName}`)
      })

      return {
        ...config,
        data: filteredData,
      }
    })
  }, [graphConfig, checkedLegendItems])

  const maxYDomain = getStackedMaxYDomain(
    filteredGraphConfig || [],
    yAccessorKey,
  )

  return (
    <>
      <StackedBarChart
        graphConfig={graphConfig}
        graphConfigFiltered={filteredGraphConfig || null}
        yAccessorKey={yAccessorKey}
        yDomain={[0, maxYDomain]}
        yTickFormat={yFormatFn}
        tooltipHeading={(selectedId?: string | null, activePoint?: any) => {
          if (selectedId) {
            return middleEllipsis(selectedId, 20, 20)
          }

          const date = dayjs(activePoint?._x)
          if (!date.isValid()) return ''

          return <TimeRangeFromPeriod start={date} timePeriod={timePeriod} />
        }}
        containerProps={containerProps}
        barProps={barProps}
        selectedId={selectedId}
        width={width}
        height={height}
        padding={padding}
      />
      <LegendWrapper>
        <Legend>
          {colorMap.map(({ id, color }) => {
            return (
              <LegendItem
                id={id || `unnamed-${color}`}
                key={id}
                title={id}
                color={color}
                onChange={() => handleLegendChange(id)}
                checked={checkedLegendItems.includes(id)}
              >
                {middleEllipsis(id, 10, 10)}
              </LegendItem>
            )
          })}
        </Legend>
      </LegendWrapper>
    </>
  )
}
