import { User } from '@cloudnatix-types/usercontroller'
import { isProduction } from 'src/next/utils'

export const getGrafanaUrl = (user: User | undefined): string | undefined => {
  const tenantId = user?.tenant?.id

  if (tenantId === undefined) {
    return undefined
  }

  if (tenantId === '0oa2q9onxlJUiUIUV4x7') {
    return `https://grafana.${
      import.meta.env.VITE_DOGFOODING_URL_PREFIX
    }.cloudnatix.com`
  }

  const prefixMap = {
    // Saviynt
    '0oac61540i54GOUYY4x7': 'saviynt',
    // NEC
    '0oa4ukp37kYrqmiAs4x7': 'nec',
    // Fortra
    '0oa4fbdl1ewCfEm1l4x7': 'fortra',
    // The demo tenant
    '0oacx5ji7K7xbnh2H4x6': import.meta.env.VITE_DEMO_URL_PREFIX,
  }
  const prefix = prefixMap[tenantId]
  if (prefix === undefined) {
    return undefined
  }

  return `https://grafana.monitoring.${prefix}.cloudnatix.com`
}

export const getSupersetUrl = (user: User | undefined): string | undefined => {
  const tenantId = user?.tenant?.id

  if (tenantId === undefined) {
    return undefined
  }

  const prefixMap = {
    // Coop
    '0oa2hve19cDdNX6zx4x7': 'coop',
    // Fortra
    '0oa4fbdl1ewCfEm1l4x7': 'fortra',
    // NEC Solution Innovators
    '0oa4ukp37kYrqmiAs4x7': 'nes',
    // NEHOPS
    '0oaizths5kFr09L8o4x7': 'nehops',
    // Gupshup
    '0oageyyhugwX9qm8W4x7': 'gupshup',
    // BI Dashboard Demo
    '0oahiyv9ntXv2LkPO4x7': 'bi-dashboard-demo',
    // The dogfooding tenant
    '0oa2q9onxlJUiUIUV4x7': import.meta.env.VITE_DOGFOODING_URL_PREFIX,
    // The demo tenant
    '0oacx5ji7K7xbnh2H4x6': import.meta.env.VITE_DEMO_URL_PREFIX,
  }
  const prefix = prefixMap[tenantId]
  if (prefix === undefined) {
    return undefined
  }

  return `https://superset.${prefix}.cloudnatix.com`
}

export const hasDeploymentDashboardAccess = (user: User | undefined): boolean =>
  // API is (currently) also only available for this (dogfooding) account.
  // Ideally there should be a special role for the deployment dashboard
  user?.uuid === '00u2q9qnpvq5QaGdS4x7'

export const hasAPIDocAccess = (user: User | undefined): boolean => {
  // premiumTenants lists the tenant-ids of our premium customers -- we
  // want to limit the visibility of our API doc to those premium customers.
  // See https://cloudnatix.atlassian.net/browse/CNATIX-3370.
  const premiumTenants = [
    '0oa4fbdl1ewCfEm1l4x7', // Fortra (HelpSystems)
    '0oa4ukp37kYrqmiAs4x7', // NEC
    '0oae54m9vkOfsNdrN4x7', // Megazone
    '0oaaf7g2hwPJIMdqP4x7', // CyberAgent
  ]
  return !isProduction() || premiumTenants.includes(user?.tenant?.id!)
}

export const enableComboBoxOrgSelector = (user: User | undefined): boolean => {
  const tenants = [
    '0oaetj47ip6af6MfP4x7', // japan-airlines
    '0oaaf7g2hwPJIMdqP4x7', // CyberAgent
  ]
  return tenants.includes(user?.tenant?.id!)
}
