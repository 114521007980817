import React, { ReactNode, useMemo } from 'react'
import { Time } from '@carbon/react/icons'
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu'
import { useTranslation } from 'react-i18next'

import { SummaryInterval } from '@cloudnatix-types/dashboard'

import { Box, Flex } from 'src/next/components'
import { TimeRangeOption } from 'src/next/components/dashboard/timerange/TimerangeTypes'
import Typography from 'src/next/components/Typography'
import { usePersistentOverflowMenu } from 'src/next/hooks/usePersistentOverflowMenu'

import { TimeRange } from './TimerangeTypes'

interface UseRollupPeriodProps {
  id: string
  timeRange: TimeRange
  defaultItem?: number
  onChange?: (item: TimeRangeOption) => void
}

export const useRollupPeriod = ({
  id,
  timeRange,
  defaultItem = 0,
  onChange,
}: UseRollupPeriodProps) => {
  const { t } = useTranslation()

  const timeRangeOptions = useMemo(() => {
    switch (timeRange) {
      case 'week':
        return [
          {
            id: SummaryInterval.DAILY,
            label: t('Common.TimePeriod.Daily'),
          },
          {
            id: SummaryInterval.WEEKLY,
            label: t('Common.TimePeriod.Weekly'),
          },
        ]
      case 'month':
        return [
          {
            id: SummaryInterval.DAILY,
            label: t('Common.TimePeriod.Daily'),
          },
          {
            id: SummaryInterval.WEEKLY,
            label: t('Common.TimePeriod.Weekly'),
          },
          {
            id: SummaryInterval.MONTHLY,
            label: t('Common.TimePeriod.Monthly'),
          },
        ]
      case '6months':
        return [
          {
            id: SummaryInterval.DAILY,
            label: t('Common.TimePeriod.Daily'),
          },
          {
            id: SummaryInterval.WEEKLY,
            label: t('Common.TimePeriod.Weekly'),
          },
          {
            id: SummaryInterval.MONTHLY,
            label: t('Common.TimePeriod.Monthly'),
          },
        ]
      case 'all':
      // fallthrough
      default:
        return [
          {
            id: SummaryInterval.DAILY,
            label: t('Common.TimePeriod.Daily'),
          },
          {
            id: SummaryInterval.WEEKLY,
            label: t('Common.TimePeriod.Weekly'),
          },
          {
            id: SummaryInterval.MONTHLY,
            label: t('Common.TimePeriod.Monthly'),
          },
        ]
    }
  }, [timeRange])

  const { items, selectedItem } = usePersistentOverflowMenu(
    id,
    timeRangeOptions,
    { onChange },
    defaultItem,
  )

  return {
    TimeRangeMenu,
    selectedItem,
    items,
  }
}

const TimeRangeMenu = ({
  label,
  items,
}: {
  label?: string
  items: ReactNode[]
}) => {
  const { t } = useTranslation()

  return (
    <Flex alignItems="center">
      {label && (
        <Box ml={4} mr={2}>
          <Typography variant="body-long-01">{label}</Typography>
        </Box>
      )}

      <OverflowMenu
        flipped
        iconDescription={t(
          'Common.DateFilter.SelectRollupPeriod',
          'Choose a rollup period',
        )}
        aria-label={t('Common.DateFilter.SelectRollupPeriod')}
        renderIcon={Time}
      >
        {items}
      </OverflowMenu>
    </Flex>
  )
}
