import { useEffect, useMemo, useState } from 'react'
import { Dropdown, SelectSkeleton } from '@carbon/react'
import { OverflowMenu } from '@carbon/react/lib/components/OverflowMenu'
import { Time } from '@carbon/react/icons'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
  ComputeInstanceGroup,
  ComputeInstanceGroupingKeyKey,
  ComputeInstanceSummary,
  SummaryInterval,
} from '@cloudnatix-types/dashboard'

import {
  useDashboardInstanceGroups,
  useInstanceSummaries,
  useInstanceSummariesRollupQuery,
} from 'src/api'
import { Box, Flex, Link } from 'src/next/components'
import { InlineNotification } from 'src/next/components/InlineNotification'
import MiddleTruncate from 'src/next/components/MiddleTruncate'
import { useComputeInstanceSummariesMetricsDropdownItems } from 'src/next/components/dashboard/tabs/charts/useFilterOptions'
import { CollapseComponent } from 'src/next/components/ui'
import { computeInstanceSummaryMetricMap } from 'src/next/constants/summary'
import { TimePeriod } from 'src/next/utils/time'
import { usePropsForStackedSummariesChart } from 'src/next/hooks/usePropsForStackedSummariesChart'
import { usePersistentDropdown } from 'src/next/hooks/usePersistentDropdown'
import {
  createFormatFn,
  getCarbonPaletteCssVariable,
  getFormatType,
} from 'src/next/utils/graph.utils'
import { GraphColor } from 'src/next/types/workloads'

import { useRollupPeriod } from '../timerange'
import { TabDataTable } from './tables'
import { useGroupingHeaders } from './useGroupingHeaders'
import { GenericTabTrendViewChart } from './charts/GenericTabTrendViewChart'

interface LocationState {
  pathname: string
  search: string
}

interface TransformedDataItem extends ComputeInstanceSummary {
  id: string
  name: JSX.Element
  color: GraphColor
}

const transformComputeInstanceGroupingData = (
  groupingKey: string,
  // `GetTopSummariesResponse` and `GetTopComputeInstanceSummariesResponse` differ only slightly.
  //
  // Eventually we might want to combine the endpoints of:
  // - /v1/dashboard/infrastructure/compute/instancesummaries:rollup (for generic tab) &
  // - /v1/dashboard/kubernetes/summaries:rollup (for Namespace)
  // altogether and merge the components.
  summaries: ComputeInstanceSummary[],
  linkState: LocationState,
): TransformedDataItem[] => {
  const formattedSummaries = summaries.map(summary => {
    const groupName = summary.groupingName!

    const nameElement = (
      <MiddleTruncate text={groupName} charsAtStart={30} charsAtEnd={30} />
    )

    const formattedSummary = {
      ...summary,
      id: groupName,
      name:
        groupingKey === 'INSTANCE_ID' ? (
          <Link
            to={`/app/vm-workload/${encodeURIComponent(
              groupName,
            )}?tabs=recommendations`}
            state={linkState}
          >
            {nameElement}
          </Link>
        ) : (
          nameElement
        ),
    } as Omit<TransformedDataItem, 'color'>

    Object.values(computeInstanceSummaryMetricMap).forEach(metric => {
      formattedSummary[`${metric}Formatted`] = createFormatFn(
        getFormatType(metric),
        {
          cpu: { shortDisplayValue: true },
        },
      )(summary[metric])
    })

    return formattedSummary
  })

  // Sort and assign colors.
  return formattedSummaries
    .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
    .map((item, i) => ({
      color: getCarbonPaletteCssVariable(i),
      ...item,
    }))
}

const getStartAndEndTime = (timePeriod: TimePeriod) => {
  const lastHour = dayjs().startOf('hour')

  const subtract: [number, dayjs.ManipulateType] =
    timePeriod === 'daily'
      ? [1, 'month']
      : timePeriod === 'weekly'
      ? [6, 'months']
      : timePeriod === 'monthly'
      ? [1, 'year']
      : [1, 'month']

  const startTimeNs = `${lastHour.subtract(...subtract).valueOf() * 1_000_000}`
  const endTimeNs = `${lastHour.valueOf() * 1_000_000}`

  return { startTimeNs, endTimeNs }
}

const useDurationString = (timePeriod: TimePeriod) => {
  const { t } = useTranslation()

  // Call the `t` function here than inside the backquoted texts below to get them captured by `yarn translations:scan`.
  const month = t('Common.Time.Month')
  const months = t('Common.Time.Months')
  const year = t('Common.Time.Year', 'year')

  return timePeriod === 'daily'
    ? t('Common.PastX', { val: `1 ${month}` })
    : timePeriod === 'weekly'
    ? t('Common.PastX', { val: `6 ${months}` })
    : t('Common.PastX', { val: `1 ${year}` })
}

interface IndividualGroupDropdownProps {
  id: string
  groups: ComputeInstanceGroup[]
  setSelectedItem: (id: string) => void
}

const IndividualGroupDropdown = ({
  id,
  groups,
  setSelectedItem,
}: IndividualGroupDropdownProps) => {
  const { t } = useTranslation()

  const items = useMemo(
    () =>
      groups
        .sort((a, b) => {
          if (a.name! < b.name!) return -1
          if (a.name! > b.name!) return 1
          return 0
        })
        .map(({ name, uid }) => ({
          id: uid!,
          label: name!,
        })),
    [groups],
  )

  const {
    dropdownProps: { selectedItem, ...individualGroupDropdownProps },
  } = usePersistentDropdown(id, items)

  useEffect(() => {
    setSelectedItem(selectedItem.id)
  }, [selectedItem, setSelectedItem])

  return (
    <Dropdown
      label={t('Workloads.TopCharts.Rollup.Heading.IndividualGroup')}
      hideLabel
      selectedItem={selectedItem}
      {...individualGroupDropdownProps}
    />
  )
}

export interface GenericTabTrendViewProps {
  id: string
  groupingKeyKey: `${ComputeInstanceGroupingKeyKey}`
  groupingKeyTagKey: string | undefined
  noLimit: boolean
}

export const GenericTabTrendView = ({
  id,
  groupingKeyKey,
  groupingKeyTagKey,
  noLimit,
}: GenericTabTrendViewProps) => {
  const { t } = useTranslation()

  const limitItems = useMemo(
    () => [
      {
        id: 'limit5',
        limitValue: 5,
        label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 5`,
      },
      {
        id: 'limit10',
        limitValue: 10,
        label: `${t('Workloads.TopCharts.Rollup.FilterItems.Top')} 10`,
      },
      ...(noLimit === true
        ? [
            {
              id: 'noLimit',
              limitValue: 0,
              label: t('Workloads.TopCharts.Rollup.FilterItems.All'),
            },
          ]
        : []),
      {
        id: 'individual',
        limitValue: 1,
        label: t('Workloads.TopCharts.Rollup.FilterItems.Individual'),
      },
    ],
    [noLimit, t],
  )

  const {
    dropdownProps: { selectedItem: limit, ...limitDropdownProps },
  } = usePersistentDropdown(`${id}-limit`, limitItems)

  const orgOptionItems = [
    ...(limit.id !== 'individual'
      ? [
          {
            id: 'OnlyDirectChildren',
            label: t(
              'Workloads.TopCharts.Rollup.FilterItems.OnlyDirectChildren',
            ),
          },
        ]
      : []),
    {
      id: 'AllDescendants',
      label: t('Workloads.TopCharts.Rollup.FilterItems.AllDescendants'),
    },
  ]
  const {
    dropdownProps: { selectedItem: orgOption, ...orgOptionDropdownProps },
  } = usePersistentDropdown(`${id}-org-option`, orgOptionItems)

  const isOrgGroupingTopNMode =
    groupingKeyKey === 'ORG' && limit.id !== 'individual'

  // For the combination of the org grouping mode and the top N mode, we provide an option to switch between 2 modes about descendant handling.
  // When the mode is "all descendants", we use the "ORG_EXCLUDING_SUB" grouping key to retrieve aggregated data counting
  // only compute instances directly belonging to each descendant org.
  // On the other hand, when the mode is "only direct children", we use the "ORG" grouping to retrieve aggregated data counting
  // compute instances belonging to all the descendants of each direct children org.
  //
  // For the individual mode, for now, we provide only the "all descendants" option.
  // Showing only the compute instances directly belong to the selected group might be useful for some people,
  // but it's a bit difficult to provide the option without causing confusion.
  const groupingKey = {
    key:
      isOrgGroupingTopNMode && orgOption.id === 'AllDescendants'
        ? 'ORG_EXCLUDING_SUB'
        : (groupingKeyKey as any),
    tagKey: groupingKeyTagKey,
  }

  const {
    items: durationOverflowMenuItems,
    selectedItem: durationSelectedItem,
  } = useRollupPeriod({
    id: `${id}-duration`,
    timeRange: 'all',
  })

  const timePeriod = durationSelectedItem.id.toLowerCase() as TimePeriod

  const { startTimeNs, endTimeNs } = getStartAndEndTime(timePeriod)
  const durationString = useDurationString(timePeriod)

  const summaryInterval = durationSelectedItem.id as SummaryInterval

  const {
    isFetching: isInstanceGroupsDataFetching,
    isError: isInstanceGroupsDataError,
    data: instanceGroupsData,
  } = useDashboardInstanceGroups(
    {
      groupingKey,
      filter: {
        startTimeNs,
        endTimeNs,
      },
    },
    {
      enabled: limit.id === 'individual',
    },
  )

  const [selectedIndividualGroup, setSelectedIndividualGroup] = useState<
    string | undefined
  >(undefined)

  const metricItems = useComputeInstanceSummariesMetricsDropdownItems()
  const {
    dropdownProps: { selectedItem: metric, ...metricDropdownProps },
  } = usePersistentDropdown(`${id}-metric`, metricItems)

  const {
    data: rollupData,
    isIdle: isRollupIdle,
    isLoading: isRollupLoading,
    isError: isRollupError,
    error: rollupError,
  } = useInstanceSummariesRollupQuery(
    {
      limit: limit.limitValue,
      summaryMetrics: metric.id as any,
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingKey,
          duration: summaryInterval,
        },
      },
      ...(isOrgGroupingTopNMode &&
        orgOption.id === 'OnlyDirectChildren' && {
          onlyDirectChildren: true,
        }),
    },
    {
      enabled: limit.id !== 'individual',
    },
  )

  const {
    data: summariesData,
    isIdle: isSummariesIdle,
    isLoading: isSummariesLoading,
    isError: isSummariesError,
    error: summariesError,
  } = useInstanceSummaries(
    {
      filter: {
        startTimeNs,
        endTimeNs,
        grouping: {
          groupingKey,
          groupingValue: selectedIndividualGroup,
          duration: summaryInterval,
        },
      },
    },
    {
      enabled:
        limit.id === 'individual' && selectedIndividualGroup !== undefined,
    },
  )

  const { data, isIdle, isLoading, isError, error } = (() => {
    if (limit.id === 'individual') {
      return {
        data: summariesData
          ? summariesData.summaries!.map(summary => [summary])
          : [],
        isIdle: isSummariesIdle,
        isLoading: isSummariesLoading,
        isError: isSummariesError,
        error: summariesError,
      }
    }

    return {
      data: rollupData
        ? Object.values(rollupData.topSummaries!).map(
            ({ summaries }) => summaries!,
          )
        : [],
      isIdle: isRollupIdle,
      isLoading: isRollupLoading,
      isError: isRollupError,
      error: rollupError,
    }
  })()

  const headers = useGroupingHeaders({
    metrics: computeInstanceSummaryMetricMap,
    overrideNameColumnHeader: groupingKeyTagKey,
  })

  const location = useLocation()
  const linkState = useMemo(
    () => ({
      pathname: location.pathname,
      search: location.search,
    }),
    [location],
  )

  const formattedRows = useMemo(() => {
    const summaries =
      limit.id == 'individual'
        ? summariesData
          ? [summariesData.aggregatedSummary!.aggregatedSummary!]
          : []
        : rollupData
        ? rollupData.groupSummaries!
        : []

    return transformComputeInstanceGroupingData(
      groupingKeyKey,
      summaries,
      linkState,
    )
  }, [limit.id, rollupData, summariesData, groupingKeyKey, linkState])

  const { rows, selectedId, colorMap, barProps } =
    usePropsForStackedSummariesChart({
      data: formattedRows,
    })

  return (
    <>
      <Box mt={5} mb={3}>
        <Flex>
          <Box mr="auto">
            <Flex gap="var(--cds-spacing-04)">
              <Dropdown
                label={t('Workloads.TopCharts.Rollup.Heading.Limit')}
                hideLabel
                selectedItem={limit}
                {...limitDropdownProps}
              />
              {isOrgGroupingTopNMode ? (
                <Dropdown
                  label={t('Workloads.TopCharts.Rollup.Heading.OrgOption')}
                  hideLabel
                  selectedItem={orgOption}
                  {...orgOptionDropdownProps}
                />
              ) : null}
              {limit.id === 'individual' ? (
                <Box minWidth="200px">
                  {isInstanceGroupsDataFetching ? (
                    <SelectSkeleton hideLabel />
                  ) : isInstanceGroupsDataError ||
                    instanceGroupsData === undefined ? (
                    <InlineNotification
                      title={t(
                        'Workloads.TopCharts.Rollup.Heading.IndividualGroup.LoadingError',
                      )}
                      kind="error"
                    />
                  ) : instanceGroupsData.length === 0 ? (
                    <InlineNotification
                      title={t(
                        'Workloads.TopCharts.Rollup.Heading.IndividualGroup.NoData',
                      )}
                      kind="info"
                    />
                  ) : (
                    <IndividualGroupDropdown
                      id={`${id}-individual-group`}
                      groups={instanceGroupsData}
                      setSelectedItem={setSelectedIndividualGroup}
                    />
                  )}
                </Box>
              ) : null}
              <Dropdown
                label={t('Workloads.TopCharts.Rollup.Heading.Metric')}
                hideLabel
                selectedItem={metric}
                {...metricDropdownProps}
              />
            </Flex>
          </Box>
          <OverflowMenu
            data-testid={`${id}-duration`}
            flipped
            iconDescription={t('Common.DateFilter.SelectRollupPeriod')}
            aria-label={t('Common.DateFilter.SelectRollupPeriod')}
            renderIcon={Time}
          >
            {durationOverflowMenuItems}
          </OverflowMenu>
        </Flex>
      </Box>
      {isError ? (
        <InlineNotification
          title={`${t('Workloads.TopCharts.Rollup.LoadingError')} ${
            error?.data?.message
          }`}
          kind="error"
        />
      ) : (
        <>
          <CollapseComponent>
            <Box minHeight={300}>
              <GenericTabTrendViewChart
                data={data}
                isLoading={isIdle || isLoading}
                duration={timePeriod}
                summaryMetric={metric.id}
                selectedId={selectedId}
                colorMap={colorMap}
                barProps={barProps}
              />
            </Box>
          </CollapseComponent>
          <Box mt={5}>
            <TabDataTable
              id={`${id}-table`}
              title={t('Common.Rollup', { timePeriod: durationString })}
              headers={headers}
              rows={rows}
              isLoading={isIdle || isLoading}
            />
          </Box>
        </>
      )}
    </>
  )
}
