import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { VictoryBarProps } from 'victory'

import { LegendItem } from 'src/next/components'
import { TransformedTopSummaries } from 'src/next/components/dashboard/tabs/tables/transformGroupingData'

const Wrapper = styled.div`
  display: inline-block;

  li {
    margin: 0;
  }
`

export interface UsePropsForStackedSummariesChartProps {
  data: TransformedTopSummaries[]
}

export const usePropsForStackedSummariesChart = ({
  data,
}: UsePropsForStackedSummariesChartProps) => {
  const [selectedId, setSelectedId] = useState<string>('')

  // reset selection when different filter options are chosen
  useEffect(() => {
    setSelectedId('')
  }, [data])

  const barProps: VictoryBarProps = useMemo(() => {
    return {
      events: [
        {
          target: 'data',
          eventHandlers: {
            onClick: (_, { datum }) => {
              const id = datum?.groupingName
              setSelectedId(selectedId === id ? '' : id)
            },
          },
        },
      ],
    }
  }, [selectedId, setSelectedId])

  // cannot be memoized because it on the data
  const rows = data.map(item => {
    const isSelected = selectedId === item.id

    return {
      ...item,
      legendSelection: (
        <Wrapper>
          <LegendItem
            id={`datatable-legend-${item.id}`}
            color={item.color}
            readOnly
            checked={selectedId ? isSelected : true}
            isSelected={!!selectedId}
          />
        </Wrapper>
      ),
      onClick: (row: TransformedTopSummaries) => {
        setSelectedId(selectedId === row.id ? '' : row.id)
      },
      isSelected,
    }
  })

  const colorMap = useMemo(() => {
    return data.map(({ id, color }) => ({ id, color }))
  }, [data])

  return {
    rows,
    selectedId,
    colorMap,
    barProps,
  }
}
